import translate from './services/translationService';
const isLocal = (() => {
    const script = document.querySelector('#smartagent');
    return script.classList.contains('local');
})();
export const getEnv = () => {
    if (isLocal)
        return 'dev';
    const script = document.querySelector('#smartagent');
    for (const env of ['dev', 'uat']) {
        if (script.src.includes(env)) {
            return env;
        }
    }
    return 'prod';
};
export const getLoaderScriptAttributes = () => {
    var _a, _b, _c, _d, _e;
    const script = document.querySelector('#smartagent');
    const company = (_a = script.getAttribute('company')) !== null && _a !== void 0 ? _a : script.getAttribute('data-company');
    const facia = (_c = (_b = script.getAttribute('facia')) !== null && _b !== void 0 ? _b : script.getAttribute('data-facia')) !== null && _c !== void 0 ? _c : company;
    const language = (_e = (_d = script.getAttribute('language')) !== null && _d !== void 0 ? _d : script.getAttribute('data-language')) !== null && _e !== void 0 ? _e : getLanguage();
    return { company, facia, language };
};
export const getChatURL = (chatFlowId) => {
    const { company, facia, language } = getLoaderScriptAttributes();
    const base = {
        "dev": "https://dev-chat.smartagent.app",
        "uat": "https://uat-chat.smartagent.app",
        "prod": "https://chat.smartagent.app",
    }[getEnv()];
    let queryString = `?chatFlowId=${chatFlowId}&company=${company}&facia=${facia}`;
    if (language) {
        queryString += `&language=${language}`;
    }
    return base + queryString;
};
const getLanguage = () => {
    const { lang = 'en' } = document.documentElement;
    const [language] = lang.split('-');
    return language;
};
export const getNonce = () => {
    const mainJSImport = document.getElementById('smartagent');
    return mainJSImport.nonce || mainJSImport.getAttribute('nonce') || null;
};
export const loadCSS = async () => {
    if (isLocal)
        return;
    const { company, facia } = getLoaderScriptAttributes();
    const url = `${getScriptBaseUrl()}/css/${company}/${facia}/loader.css`;
    const link = document.createElement('link');
    link.href = url;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    // Add nonce to CSS import
    link.setAttribute('nonce', getNonce());
    document.head.appendChild(link);
};
const getScriptBaseUrl = () => {
    const script = document.querySelector('#smartagent');
    if (script.classList.contains('local')) {
        return 'https://dev-chat-loader.smartagent.app';
    }
    return script.src.split('/').slice(0, -2).join('/');
};
export const getWrapper = () => {
    return document.querySelector('.sa-chat-wrapper');
};
export const getTab = () => {
    return document.querySelector('.sa-chat-tab');
};
export const getSlideout = () => {
    return document.querySelector('.sa-chat-slideout');
};
export const getIframe = () => {
    return document.querySelector('.sa-chat-iframe');
};
export const getNavMenu = () => {
    return document.querySelector('.sa-chat-header-nav-menu');
};
export const getAudioAlertsWrapper = () => document.querySelector('.sa-chat-header-audio-alert-wrapper');
export const notificationCounter = (language) => {
    const notificationCounter = document.querySelector('.sa-message-count');
    const textCounter = document.querySelector('.sa-message-announce');
    const messageCount = notificationCounter.innerHTML;
    const increase = () => {
        const currentCount = Number(messageCount);
        const nextCount = currentCount + 1;
        notificationCounter.innerHTML = nextCount.toString();
        if (currentCount === 0)
            notificationCounter.style.display = 'flex';
        textCounter.innerHTML = `${nextCount} ${nextCount === 1
            ? `${translate('new chat message', language)}`
            : `${translate('new chat messages', language)}`}`;
    };
    const reset = () => {
        notificationCounter.style.display = 'none';
        notificationCounter.innerHTML = '0';
        textCounter.innerHTML = '';
    };
    return {
        increase,
        reset
    };
};
export const getTitleNotificationManager = () => {
    const { language } = getLoaderScriptAttributes();
    const testRegex = new RegExp(`\\[(\\d+)\\] ${translate('New Messages', language)}? \\| `);
    let newMessages = 0;
    let enabled = false;
    let initialised = false;
    const newMessage = () => {
        if (!enabled || !document.hidden)
            return;
        newMessages += 1;
        let clearTitle = document.title.replace(testRegex, "");
        const newMessagesText = newMessages > 1
            ? translate('New Messages', language)
            : translate('New Message', language);
        document.title = `[${newMessages}] ${newMessagesText} | ${clearTitle}`;
    };
    const clear = () => {
        if (!enabled)
            return;
        newMessages = 0;
        console.log('resetting title notifications', document.title);
        document.title = document.title.replace(testRegex, "");
    };
    const setup = (enableTitleNotifications) => {
        if (initialised)
            return;
        enabled = enableTitleNotifications;
        initialised = true;
        if (enabled) {
            console.log('title notifications enabled, setting event listener');
            window.addEventListener('focus', clear);
        }
    };
    return {
        newMessage,
        clear,
        setup
    };
};
