import buildHTML from './pug/index.pug';
import sa from './services/sa';
import './services/event';
import { getNonce, loadCSS } from './util';
import translate from './services/translationService';
const loader = (config) => {
    loadCSS();
    sa(config);
    const html = buildHTML(Object.assign(Object.assign({}, config.appConfig.loaderConfig), { usePhrase: (phrase) => translate(phrase, config.language) }));
    // Retrieve the nonce
    const nonce = getNonce();
    // If nonce is found, add it to the appropriate tags in your HTML string
    const htmlWithNonce = nonce ? html.replace(/<link/g, `<link nonce="${nonce}"`) : html;
    document.body.insertAdjacentHTML("beforeend", htmlWithNonce);
};
export default loader;
