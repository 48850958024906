import { NotificationService } from '../notification';
import { getAudioAlertsWrapper, getIframe, getNavMenu, getTitleNotificationManager, getWrapper, notificationCounter, getTab, } from '../util';
import translate from '../services/translationService';
export const localStorage = (() => {
    let hasStoragePermissions = false;
    try {
        window.localStorage.getItem('');
        hasStoragePermissions = true;
    }
    catch (error) {
        hasStoragePermissions = false;
    }
    const getItem = (key) => hasStoragePermissions ? window.localStorage.getItem(key) : '';
    const setItem = (key, value) => hasStoragePermissions ? window.localStorage.setItem(key, value) : void 0;
    const removeItem = (key) => hasStoragePermissions ? window.localStorage.removeItem(key) : void 0;
    return {
        getItem,
        setItem,
        removeItem,
    };
})();
let queuedUpdateContactRequests = [];
let chatLoaded = false;
let hasChatInputFocus = false;
var AudioAlertStatus;
(function (AudioAlertStatus) {
    AudioAlertStatus[AudioAlertStatus["MUTED"] = 0] = "MUTED";
    AudioAlertStatus[AudioAlertStatus["UNMUTED"] = 1] = "UNMUTED";
    AudioAlertStatus[AudioAlertStatus["RINGING"] = 2] = "RINGING";
})(AudioAlertStatus || (AudioAlertStatus = {}));
const titleNotificationManager = getTitleNotificationManager();
const sa = ({ chatUrl, displayOnLoad, appConfig, language }) => {
    window.addEventListener('message', event => receiveMessage(event, appConfig, language), false);
    // Listen for keydown events on consumer window and chat loader, iframe interactions are handled via `message` event.
    window.addEventListener('keydown', keyDownHandler);
    const { showMessageCount, enableTitleNotifications } = appConfig.loaderConfig;
    titleNotificationManager.setup(!!enableTitleNotifications);
    let openedOnce = false;
    const chatOpenClassName = 'sa-chatbot-open';
    const navOpenClassName = 'sa-nav-menu-open';
    const audioAlert = new Audio('https://prod-cdn-12059162352.s3.eu-west-2.amazonaws.com/audio/sa-chat-new-message.wav');
    const getAlertStatus = () => {
        const wrapper = getAudioAlertsWrapper();
        const currentElement = wrapper.querySelector('svg:not(.sa-chat-header-hidden-element)');
        return currentElement.classList.contains('sa-chat-header-audio-alert-unmuted')
            ? AudioAlertStatus.UNMUTED
            : currentElement.classList.contains('sa-chat-header-audio-alert-muted')
                ? AudioAlertStatus.MUTED
                : AudioAlertStatus.RINGING;
    };
    const toggleAudioAlerts = () => {
        const wrapper = getAudioAlertsWrapper();
        const unmuted = wrapper.querySelector('.sa-chat-header-audio-alert-unmuted');
        const muted = wrapper.querySelector('.sa-chat-header-audio-alert-muted');
        const ring = wrapper.querySelector('.sa-chat-header-audio-alert-ring');
        const status = getAlertStatus();
        if (status === AudioAlertStatus.UNMUTED) {
            unmuted.classList.add('sa-chat-header-hidden-element');
            muted.classList.remove('sa-chat-header-hidden-element');
        }
        else if (status === AudioAlertStatus.MUTED) {
            muted.classList.add('sa-chat-header-hidden-element');
            unmuted.classList.remove('sa-chat-header-hidden-element');
        }
        else if (status === AudioAlertStatus.RINGING) {
            ring.classList.add('sa-chat-header-hidden-element');
            unmuted.classList.remove('sa-chat-header-hidden-element');
        }
    };
    const audioRingAlert = () => {
        const wrapper = getAudioAlertsWrapper();
        const ring = wrapper.querySelector('.sa-chat-header-audio-alert-ring');
        const currentElement = wrapper.querySelector('svg:not(.sa-chat-header-hidden-element)');
        if (!hasChatInputFocus) {
            currentElement.classList.add('sa-chat-header-hidden-element');
        }
        const play = () => {
            if (!hasChatInputFocus) {
                ring.classList.remove('sa-chat-header-hidden-element');
            }
            audioAlert.play();
        };
        const stop = () => {
            wrapper
                .querySelector('.sa-chat-header-audio-alert-unmuted')
                .classList.remove('sa-chat-header-hidden-element');
            audioAlert.pause();
        };
        return { play, stop };
    };
    const toggleChat = () => {
        const { classList } = document.body;
        const chatOpenImage = document.getElementsByClassName('chat-tab-image')[0];
        if (!openedOnce) {
            openedOnce = true;
            getIframe().setAttribute('src', chatUrl);
            if (appConfig.chatName) {
                getIframe().setAttribute('name', appConfig.chatName);
            }
            if (appConfig.chatTitle) {
                getIframe().setAttribute('title', appConfig.chatTitle);
            }
            const wrapper = getWrapper();
            Object.entries(appConfig.theme).forEach(([key, value]) => {
                wrapper.style.setProperty(`--sa-${key}`, value);
            });
        }
        if (!classList.contains(chatOpenClassName)) {
            if (showMessageCount)
                notificationCounter(language).reset();
            if (chatOpenImage) {
                chatOpenImage.setAttribute('alt', translate('Close Chat', language));
            }
            return classList.add(chatOpenClassName);
        }
        setTimeout(() => {
            var _a;
            classList.remove(chatOpenClassName);
            if (chatOpenImage) {
                chatOpenImage.setAttribute('alt', translate('Open Chat', language));
            }
            if ((_a = getNavMenu()) === null || _a === void 0 ? void 0 : _a.classList.contains(navOpenClassName))
                toggleNavMenu();
            getTab().focus();
        }, 0);
    };
    const downloadChatTranscript = () => {
        getIframe().contentWindow.postMessage({
            type: 'smartagent',
            event: 'download-chat-transcript',
        }, '*');
    };
    const toggleNavMenu = () => {
        const { classList } = getNavMenu();
        if (classList.contains(navOpenClassName))
            return classList.remove(navOpenClassName);
        classList.add(navOpenClassName);
        const keyUpEvent = (event) => {
            if (event.key === 'Escape') {
                classList.remove(navOpenClassName);
                document.removeEventListener('keyup', keyUpEvent);
            }
        };
        document.addEventListener('keyup', keyUpEvent);
    };
    const toggleNotifications = () => {
        const checkbox = document.querySelector('.sa-chat-header-nav-menu .sa-chat-header-toggle-checkbox');
        const notificationsMenuOption = document.querySelector('.sa-chat-header-nav-menu .sa-chat-header-notifications-option');
        const newState = !(checkbox === null || checkbox === void 0 ? void 0 : checkbox.checked);
        notificationsMenuOption.setAttribute('aria-checked', newState.toString());
        checkbox.checked = newState;
    };
    const isOpen = () => {
        return !!document.body.classList.contains(chatOpenClassName);
    };
    const openChat = () => {
        if (isOpen())
            return;
        toggleChat();
    };
    const closeChat = () => {
        if (!isOpen())
            return;
        toggleChat();
    };
    const endChat = () => {
        getIframe().contentWindow.postMessage({
            type: 'smartagent',
            event: 'loader-close-chat',
        }, '*');
        chatLoaded = false;
    };
    const updateContact = (data) => {
        try {
            if (!data) {
                throw new Error('No data provided to update contact.');
            }
            Object.keys(data.attributes).forEach(key => {
                if (typeof key !== 'string') {
                    throw new Error(`Key value ${key} must be a string`);
                }
                if (typeof data.attributes[key] !== 'string') {
                    throw new Error(`Value of key ${key} (${data.attributes[key]}) must be a string.`);
                }
            });
            data.attributes = Object.entries(data.attributes).reduce((attrs, [key, val]) => (Object.assign(Object.assign({}, attrs), { [key.startsWith('sa-websitedata-') ? key : 'sa-websitedata-' + key]: val })), {});
            if (!chatLoaded) {
                queuedUpdateContactRequests.push(data);
                console.log('queuing contact update request...');
                return;
            }
            getIframe().contentWindow.postMessage({
                type: 'smartagent',
                event: 'update-contact',
                data,
            }, '*');
        }
        catch (e) {
            throw new Error(e);
        }
    };
    if (!openedOnce && displayOnLoad && localStorage.getItem('smartagent_connection/global')) {
        setTimeout(() => {
            toggleChat();
        }, 500);
    }
    const hide = () => (getWrapper().style.display = 'none');
    const show = () => (getWrapper().style.display = 'block');
    const sa = {
        isOpen,
        openChat,
        closeChat,
        toggleChat,
        downloadChatTranscript,
        toggleNavMenu,
        toggleNotifications,
        toggleAudioAlerts,
        audioRingAlert,
        getAlertStatus,
        endChat,
        updateContact,
        show,
        hide,
    };
    window._sa = sa;
    return sa;
};
var ChatAppEventTypes;
(function (ChatAppEventTypes) {
    ChatAppEventTypes["GET_APP_CONFIG"] = "get-app-config";
    ChatAppEventTypes["CONTACT_STARTED"] = "contact-started";
    ChatAppEventTypes["CONTACT_ENDED"] = "contact-ended";
    ChatAppEventTypes["SMARTAGENT_MINIMISE_LOADER"] = "smartagent-minimise-loader";
    ChatAppEventTypes["SMARTAGENT_GET_CSS_VARS"] = "smartagent-get-css-variables";
    ChatAppEventTypes["START_DISCONNECT_FLOW"] = "start-disconnect-flow";
    ChatAppEventTypes["SET_SMARTAGENT_PERSISTANCE_CONTACTID"] = "set-smartagent-persistance-contactId";
    ChatAppEventTypes["GET_SMARTAGENT_PERSISTANCE_CONTACTID"] = "get-smartagent-persistance-contactId";
    ChatAppEventTypes["RECEIVE_SMARTAGENT_PERSISTANCE_CONTACTID"] = "recieve-smartagent-persistance-contactId";
    ChatAppEventTypes["DELETE_SMARTAGENT_PERSISTANCE_ITEM"] = "delete-smartagent-persistance-item";
    ChatAppEventTypes["GET_GLOBAL_PAGE_STATUS"] = "get-smartagent-global-page-status";
    ChatAppEventTypes["DELETE_SMARTAGENT_GLOBAL_PERISTANCE"] = "delete-smartagent-global-persistance";
    ChatAppEventTypes["UPDATE_CONTACT_ERROR"] = "update-contact-error";
    ChatAppEventTypes["NEW_MESSAGE_NOTIFICATION"] = "new-message-notification";
    ChatAppEventTypes["USER_CHAT_INPUT_FOCUS"] = "user-chat-input-focus";
    ChatAppEventTypes["USER_CHAT_INPUT_BLUR"] = "user-chat-input-blur";
    ChatAppEventTypes["USER_CHAT_FOCUS"] = "user-chat-focus";
    ChatAppEventTypes["CLOSE_IFRAME"] = "close-iframe";
})(ChatAppEventTypes || (ChatAppEventTypes = {}));
const receiveMessage = (event, appConfig, language) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { showMessageCount, enableBrowserNotifications, browserNotificationBrandName, enableAudioAlerts, } = appConfig.loaderConfig;
    switch (event.data.type) {
        case ChatAppEventTypes.GET_APP_CONFIG: {
            const iframe = getIframe();
            (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                type: 'receive-app-config',
                content: appConfig,
            }, '*');
            break;
        }
        case ChatAppEventTypes.CONTACT_STARTED: {
            console.log('contact started...', queuedUpdateContactRequests);
            queuedUpdateContactRequests.map(x => getIframe().contentWindow.postMessage({
                type: 'smartagent',
                event: 'update-contact',
                data: x,
            }, '*'));
            queuedUpdateContactRequests = [];
            chatLoaded = true;
            break;
        }
        case ChatAppEventTypes.CONTACT_ENDED: {
            chatLoaded = false;
            if (enableAudioAlerts && window._sa.getAlertStatus() === AudioAlertStatus.RINGING)
                window._sa.audioRingAlert().stop();
            break;
        }
        case ChatAppEventTypes.SMARTAGENT_GET_CSS_VARS: {
            console.log('loader get css variables');
            const style = getComputedStyle(document.documentElement);
            (_b = getIframe().contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({
                type: 'smartagent',
                event: 'post-css-variables',
                data: {
                    ['chat-header-background-colour']: style.getPropertyValue('--sa-chat-header-background-colour'),
                    ['chat-header-text-colour']: style.getPropertyValue('--sa-chat-header-text-colour'),
                    // ["chat-end-chat-background-colour"]: style.getPropertyValue('--sa-chat-end-chat-background-colour')
                },
            }, '*');
            break;
        }
        case ChatAppEventTypes.SMARTAGENT_MINIMISE_LOADER: {
            window._sa.toggleChat();
            break;
        }
        case ChatAppEventTypes.START_DISCONNECT_FLOW: {
            chatLoaded = true;
            break;
        }
        case ChatAppEventTypes.SET_SMARTAGENT_PERSISTANCE_CONTACTID: {
            const { cacheKey, contactId, globalRestoreSession } = event.data.content;
            localStorage.setItem(cacheKey, contactId);
            globalRestoreSession &&
                localStorage.setItem('smartagent_connection/global', encodeURI(window.location.href));
            break;
        }
        case ChatAppEventTypes.GET_SMARTAGENT_PERSISTANCE_CONTACTID: {
            const contactId = localStorage.getItem(event.data.content);
            const iframe = getIframe();
            (_c = iframe.contentWindow) === null || _c === void 0 ? void 0 : _c.postMessage({
                type: 'recieve-smartagent-persistance-contactId',
                content: contactId,
            }, '*');
            break;
        }
        case ChatAppEventTypes.GET_GLOBAL_PAGE_STATUS: {
            const persistedURL = localStorage.getItem('smartagent_connection/global');
            const isUserOnAnotherPage = persistedURL && !((_d = window.location.href) === null || _d === void 0 ? void 0 : _d.includes(persistedURL))
                ? persistedURL
                : false;
            const iframe = getIframe();
            (_e = iframe.contentWindow) === null || _e === void 0 ? void 0 : _e.postMessage({
                type: 'recieve-smartagent-global-page-status',
                content: isUserOnAnotherPage,
            }, '*');
            break;
        }
        case ChatAppEventTypes.DELETE_SMARTAGENT_PERSISTANCE_ITEM: {
            localStorage.removeItem(event.data.content);
            break;
        }
        case ChatAppEventTypes.DELETE_SMARTAGENT_GLOBAL_PERISTANCE: {
            const persistedURL = localStorage.getItem('smartagent_connection/global');
            if (persistedURL && !((_f = window.location.href) === null || _f === void 0 ? void 0 : _f.includes(persistedURL))) {
                window._sa.hide();
            }
            localStorage.removeItem('smartagent_connection/global');
            break;
        }
        case ChatAppEventTypes.UPDATE_CONTACT_ERROR: {
            throw new Error((_g = event.data.error) !== null && _g !== void 0 ? _g : 'An unknown error occured when trying to update contact');
            break;
        }
        case ChatAppEventTypes.NEW_MESSAGE_NOTIFICATION: {
            const { classList } = document.body;
            if (showMessageCount && !classList.contains('sa-chatbot-open')) {
                notificationCounter(language).increase();
            }
            // Send the client a browser notification if they are not already on the chat
            let isSupported = false;
            let hasPermission = false;
            if (enableBrowserNotifications) {
                [isSupported, hasPermission] = NotificationService.getPermission();
            }
            const enableNotificationsFromNav = (_h = document.querySelector('.sa-chat-header-nav-menu .sa-chat-header-toggle-checkbox')) === null || _h === void 0 ? void 0 : _h.checked;
            if (isSupported &&
                hasPermission &&
                document.hidden &&
                enableBrowserNotifications &&
                (enableNotificationsFromNav !== null && enableNotificationsFromNav !== void 0 ? enableNotificationsFromNav : true)) {
                const ns = new NotificationService();
                // const content = typeof event.data.content.content === 'object' ? event.data.content.content.text : event.data.content.content
                const content = `${translate('New message in your webchat', language)}${browserNotificationBrandName
                    ? ` ${translate('from', language)} ${browserNotificationBrandName}`
                    : ''}`;
                ns.send(event.data.content.displayName.replace('SYSTEM_MESSAGE', 'Agent'), content);
            }
            titleNotificationManager.newMessage();
            if (enableAudioAlerts && window._sa.getAlertStatus() !== AudioAlertStatus.MUTED) {
                window._sa.audioRingAlert().play();
            }
            break;
        }
        case ChatAppEventTypes.USER_CHAT_INPUT_FOCUS: {
            hasChatInputFocus = true;
            if (enableAudioAlerts && window._sa.getAlertStatus() === AudioAlertStatus.RINGING) {
                window._sa.toggleAudioAlerts();
            }
            break;
        }
        case ChatAppEventTypes.USER_CHAT_INPUT_BLUR: {
            hasChatInputFocus = false;
            break;
        }
        case ChatAppEventTypes.USER_CHAT_FOCUS: {
            titleNotificationManager.clear();
            break;
        }
        case ChatAppEventTypes.CLOSE_IFRAME: {
            window._sa.closeChat();
            break;
        }
    }
};
const keyDownHandler = (e) => {
    switch (e.key) {
        case 'Escape':
            if (!window._sa.isOpen())
                return;
            window._sa.closeChat();
            break;
        default:
            break;
    }
};
export default sa;
