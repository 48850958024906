import { ReplaySubject as Subject } from 'rx';
import '../notification';
const _saEvents = new Subject();
window.saEvents = _saEvents;
export const emitEvent = async (event) => {
    const payload = Object.assign(Object.assign({}, event), { timestamp: Date.now() });
    switch (event.type) {
        case 'CONTACT_STARTED':
            window.postMessage({
                type: 'contact-started',
                data: event.data
            });
            break;
        case 'CONTACT_ENDED':
            window.postMessage({
                type: 'contact-ended',
            });
            break;
    }
    _saEvents.onNext(payload);
};
(() => {
    emitEvent({
        type: "CHAT_LOADED",
        data: {}
    });
    window.addEventListener('message', (event) => {
        const eventData = event.data;
        if (eventData.type === 'smartagent-chat-event')
            emitEvent(eventData.content);
    });
})();
