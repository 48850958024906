import { shouldDisplayChat } from './services/api';
import { getChatURL, getLoaderScriptAttributes } from "./util";
import bchat from '@missionlabs/smartagent-bchat';
const runChatLoader = async (recursive = false) => {
    var _a, _b;
    const { company, facia, language } = getLoaderScriptAttributes();
    const { displayChat, chatFlowId, appConfig, displayOnLoad } = await shouldDisplayChat(company, facia);
    try {
        if (!displayChat) {
            // Remove any left over global sessions
            const globalURL = localStorage.getItem('smartagent_connection/global');
            if (globalURL) {
                localStorage.removeItem('smartagent_connection/global');
                if (!recursive)
                    return runChatLoader(true);
            }
            return console.log('display check failed will not load chat');
        }
        const { globalRestoreSession, connectionDetails, } = appConfig;
        const globalUrl = localStorage.getItem('smartagent_connection/global');
        const contactId = localStorage.getItem(`smartagent_connection_contactid/${connectionDetails.contactFlowId}`);
        if (globalRestoreSession && globalUrl && contactId) {
            const wsUrl = connectionDetails.websocketUrl +
                // Appends an API key if one is defined.
                (connectionDetails.apiKey
                    ? '?Authorization=' + connectionDetails.apiKey
                    : '');
            const ws = await bchat({
                wsUrl,
                connectionTimeoutMs: 7000
            });
            const session = await ws.describeContactEvent(contactId);
            if ((_a = session === null || session === void 0 ? void 0 : session.Contact) === null || _a === void 0 ? void 0 : _a.DisconnectTimestamp) {
                console.log('disconnected session run check again');
                localStorage.removeItem('smartagent_connection/global');
                const cacheKey = `smartagent_connection_contactid/${connectionDetails === null || connectionDetails === void 0 ? void 0 : connectionDetails.contactFlowId}`;
                localStorage.removeItem(cacheKey);
                return !recursive && runChatLoader(true);
            }
            if (!((_b = window.location.href) === null || _b === void 0 ? void 0 : _b.includes(globalUrl))) {
                try {
                    await ws.getChatSession(contactId, 'CUSTOMER');
                }
                catch (e) {
                    localStorage.removeItem('smartagent_connection/global');
                    return !recursive && runChatLoader(true);
                }
            }
        }
        (await import("./loader.js")).default({
            appConfig,
            chatFlowId,
            displayOnLoad,
            chatUrl: getChatURL(chatFlowId),
            language
        });
    }
    catch (err) {
        console.log(err);
        (await import("./loader.js")).default({
            appConfig,
            chatFlowId,
            displayOnLoad,
            chatUrl: getChatURL(chatFlowId),
            language
        });
    }
};
runChatLoader();
