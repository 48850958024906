export class NotificationService {
    constructor() {
        this.supported = false;
        this.permission = false;
        [this.supported, this.permission] = NotificationService.getPermission();
    }
    static getPermission() {
        let supported = false;
        let permission = false;
        if ('Notification' in window) {
            supported = true;
        }
        else {
            return [supported, permission];
        }
        const perm = Notification.permission;
        const isGranted = (p) => p === 'granted';
        if (perm === 'default') {
            Notification.requestPermission().then((p) => {
                permission = isGranted(p);
            });
        }
        else {
            permission = isGranted(perm);
        }
        return [supported, permission];
    }
    send(title = '', body = '', onClick) {
        if (this.supported && this.permission) {
            const n = new Notification(title, {
                body,
            });
            n.onclick = () => {
                window.focus();
                onClick === null || onClick === void 0 ? void 0 : onClick();
            };
        }
    }
}
