import { getEnv } from "../util";
import { localStorage } from './sa';
const services = {
    dev: 'https://dev-services.smartagent.app/chat-service',
    uat: 'https://uat-services.smartagent.app/chat-service',
    prod: 'https://services.smartagent.app/chat-service',
};
const BASE_URL = services[getEnv()];
const makeRequest = async ({ method = "get", url }) => {
    const data = await (await fetch(BASE_URL + url, {
        method,
    })).json();
    return data;
};
export const shouldDisplayChat = async (company, facia) => {
    const originURL = localStorage.getItem('smartagent_connection/global');
    const response = await makeRequest({
        url: `/companies/${company}/facia/${facia !== null && facia !== void 0 ? facia : company}/chatFlowId?referrerURL=${originURL !== null && originURL !== void 0 ? originURL : encodeURI(window.location.href)}`
    });
    return Object.assign(Object.assign({}, response), { displayOnLoad: !!originURL });
};
