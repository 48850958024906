const phrases = {
    'New message in your webchat': {
        cy: 'Neges newydd yn eich gew-sgwrs',
    },
    'New Message': {
        cy: 'Neges Newydd',
    },
    'New Messages': {
        cy: 'Negeseuon Newydd',
    },
    'new chat message': {
        cy: 'neges sgwrs newydd',
    },
    'new chat messages': {
        cy: 'negeseuon sgwrs newydd',
    },
    'Open Chat': {
        cy: 'Agor Sgwrs',
    },
    'Close Chat': {
        cy: 'Cau Sgwrs',
    },
    'Web Chat': {
        cy: 'Sgwrs We',
    },
    'Download Transcript': {
        cy: 'Lawrlwytho Trawsgrifiad',
    },
    Notifications: {
        cy: 'Hysbysebiadau',
    },
    'Leave Chat': {
        cy: 'Gadael Sgwrs',
    },
    'Powered by': {
        cy: "Wedi'i bweri gan",
    },
    from: {
        cy: 'gan',
    },
    'new message alerts are unmuted, press this button to mute them': {
        cy: 'rhybuddion neges newydd heb ei dawelu, pwyswch y botwm hwn i dawelu',
    },
    'new message alerts are muted, press this button to unmute them': {
        cy: 'rhybuddion neges newydd wedi tawelu, pwyswch y botwm hwn i ail-alluogi',
    },
    'you have a new message': {
        cy: 'mae gennych neges newydd',
    },
    'Minimise chat window': {
        cy: 'Lleihau ffenestr sgwrs',
    },
    'Toggle nav menu': {
        cy: 'Togl ddewislen llywio',
    },
    'Close chat window': {
        cy: 'Cau ffenestr sgwrs',
    },
};
export var Languages;
(function (Languages) {
    Languages["en"] = "en";
    Languages["cy"] = "cy";
})(Languages || (Languages = {}));
const translate = (phrase, language) => {
    if (language === Languages.en) {
        return phrase;
    }
    if (!phrases[phrase]) {
        console.error(`The phrase '${phrase}' does not exist in the list for translation.`);
        return phrase;
    }
    if (!phrases[phrase][language]) {
        console.error(`The phrase '${phrase}' has no translation for the '${language}' language. Returning the original phrase.`);
        return phrase;
    }
    return phrases[phrase][language];
};
export default translate;
